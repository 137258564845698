import { qsa } from "../utils/dom"

export function initSupportingMembersForm() {
  const forms = qsa("[data-csmf-form]")
  if (forms.length) {
    const observer = new IntersectionObserver((entries, observer) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          fetchForm(entry.target)
          observer.unobserve(entry.target)
        }
      }
    })
    for (const form of forms) observer.observe(form)
  }
}

function fetchForm(el) {
  fetch(el.dataset.csmfForm)
    .then((response) => response.text())
    .then((text) => {
      el.innerHTML = text
      initSubmitHandler(el)
    })
}

function initSubmitHandler(el) {
  const form = el.querySelector("form")
  if (form) {
    form.addEventListener("submit", (e) => {
      e.preventDefault()
      const body = new FormData(form)
      body.append("next", window.location.pathname)
      fetch(el.dataset.csmfForm, {
        method: "POST",
        credentials: "include",
        body,
      })
        .then((response) => response.text())
        .then((text) => {
          el.innerHTML = text
          initSubmitHandler(el)
        })
    })
  }
}
