import { qsa } from "/js/utils/dom"

export const initNewsletterForm = () => {
  for (const el of qsa("[data-newsletter-form]")) {
    initSubmitHandler(el)
  }
}

function initSubmitHandler(el) {
  const form = el.querySelector("form")
  if (form) {
    el.addEventListener("submit", (e) => {
      e.preventDefault()
      const body = new FormData(form)
      fetch(form.action, {
        method: "POST",
        credentials: "include",
        body,
      })
        .then((response) => response.text())
        .then((data) => {
          el.innerHTML = data
          initSubmitHandler(el)
        })
    })
  }
}
