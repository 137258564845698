export function setDetailsHeights(selector, wrapper = document) {
  const setHeights = (detail) => {
    detail.removeAttribute("style")
    detail.dataset.width = detail.offsetWidth
    detail.open = true
    detail.style.setProperty("--expanded", `${detail.offsetHeight}px`)
    detail.open = false
    detail.style.setProperty("--collapsed", `${detail.offsetHeight}px`)
  }

  const setAllHeights = () => {
    for (const detail of wrapper.querySelectorAll(selector)) {
      setHeights(detail)
    }
  }

  setAllHeights()
  window.addEventListener("load", setAllHeights)
  window.addEventListener("resize", setAllHeights)
}
